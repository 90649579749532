<template>
  <nav
    class="sub-navigation"
    :class="[
      mobilePadding ? 'mp--' + mobilePadding : '',
      mobileMargin ? 'mm--' + mobileMargin : '',
      desktopPadding ? 'dp--' + desktopPadding : '',
      desktopMargin ? 'dm--' + desktopMargin : '',
    ]"
    :style="{...bgColorVar}"
  >
    <mp-link
      v-for="(link, index) in links"
      :key="index"
      class="sub-navigation__link"
      :to="$u(link.link)"
    >
      {{ link.label }}
    </mp-link>
  </nav>
</template>

<script>
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'

export default {
  name: 'SubNavigation',
  props: {
    links: {
      type: Array,
      required: true,
      default: () => []
    },
    color: {
      type: String,
      required: false,
      default: '#616BFF'
    },
    colorCustom: {
      type: String,
      required: false,
      default: '#616BFF'
    },
    mobilePadding: {
      type: String,
      required: false,
      default: 'none'
    },
    desktopPadding: {
      type: String,
      required: false,
      default: 'none'
    },
    mobileMargin: {
      type: String,
      required: false,
      default: 'none'
    },
    desktopMargin: {
      type: String,
      required: false,
      default: 'none'
    }
  },
  computed: {
    bgColorVar() {
      return {
        '--bg-color': color(this.color, this.colorCustom)
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-navigation {
  display: flex;
  justify-content: flex-start;
  background: $white;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 0.75rem;

  &__link {
    text-align: center;
    padding: 12px 15px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 99999px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: transparent;
    border: 1px solid var(--bg-color);
    color: var(--bg-color);
    font-size: 1.2rem;
    line-height: 1.2;

    &:hover, &.nuxt-link-active {
      background-color: var(--bg-color);
      color: white;
    }

    &:first-child {
      margin-left: 1.6rem;
    }
  }
}

// Desktop styles
@media (min-width: $tablet) {
  .sub-navigation {
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: visible;
    margin-inline: auto;

    &__link {
      padding: 9px 15px 10px;
      font-size: 1.6rem;
      line-height: 1.5;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
